<template>
  <form @submit.prevent="submitForm" class="folder-form">
    <label for="folder-name">{{ $t('portfolio.folder_name') }}</label>
    <div>
      <input
        v-model="folderToCreateName"
        type="text"
        name="folder-name"
        id="folder-name"
      />
      <p class="form-error">{{ formErrors.folderName }}</p>
    </div>

    <label for="parent-folder-name">{{
      $t('portfolio.name_parent_folder')
    }}</label>
    <div class="folder-menu">
      <div
        v-for="folder in folderTree"
        :key="folder.value"
        :style="getFolderStyle(folder)"
        class="folder"
        @click="selectParentFolder(folder.value)"
      >
        {{ folder.label }}
      </div>
    </div>

    <button type="submit">{{ $t('portfolio.create_folder') }}</button>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ElMessage } from 'element-plus'

export default {
  name: 'createFolderForm',
  emits: ['close'],
  data() {
    return {
      folderToCreateName: '',
      selectedParentFolderId: '',
      formErrors: {
        folderName: null,
        parentFolderName: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      folders: 'pfmFolders',
      pfmSettings: 'pfmSettings',
    }),
    folderTree() {
      const folderList = this.folders
      const formattedData = []

      const flattenFolderList = (folders, level = 0) => {
        if (!folders || !Array.isArray(folders)) return

        folders.forEach((folder) => {
          formattedData.push({
            label: folder.label,
            value: folder.value,
            level: level,
          })

          if (folder.children && folder.children.length > 0) {
            flattenFolderList(folder.children, level + 1)
          }
        })
      }

      flattenFolderList(folderList, 0)

      return formattedData
    },
  },
  watch: {
    folderToCreateName(newValue) {
      if (newValue.length > 30) {
        this.formErrors.folderName = this.$t('portfolio.max_characters')
      } else {
        this.formErrors.folderName = null
      }
    },
  },
  methods: {
    ...mapActions({
      createFolder: 'createFolder',
    }),
    async submitForm() {
      if (this.folderToCreateName.length < 1) {
        this.formErrors.folderName = this.$t('portfolio.empty_folder_name')
      }
      if (
        this.pfmSettings.limit.folders_count >=
        this.pfmSettings.limit.folders_limit
      ) {
        ElMessage.error(
          `Vous avez atteint la limite de ${this.pfmSettings.limit.folders_limit} dossiers pour votre abonnement.`
        )
        this.$emit('closeModal')
      }

      if (this.formErrors.folderName === null) {
        try {
          await this.createFolder({
            name: this.folderToCreateName,
            options: {
              parent_folder_uuid: this.selectedParentFolderId,
            },
          })

          this.$emit('close')
        } catch {
          ElMessage({
            message: `La limite de dossier a été atteinte.`,
            type: 'error',
            grouping: true,
            duration: 5000,
          })
        }
      }
    },
    getFolderStyle(folder) {
      let style = { 'padding-left': `${folder.level * 20 + 20}px` }

      if (folder.value === this.selectedParentFolderId) {
        style['background-color'] = '#333333'
        style['border-bottom'] = '1px solid #2666ff'
      }

      return style
    },
    selectParentFolder(folderId) {
      this.selectedParentFolderId = folderId
    },
  },
  mounted() {
    this.selectedParentFolderId = this.folders[0].value
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root';
$border-color: rgba(255, 255, 255, 0.3);

.folder-form {
  padding: 1rem 0 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

button {
  border: none;
  border-radius: 50px;
  height: 40px;
  padding: 0 20px 0 20px;
  align-self: center;
  color: white;
  background-color: #1f55d8;

  &:hover {
    background-color: #143786;
  }
}

label {
  color: white;
}

input {
  background-color: $el-input-bg-color;
  border: 1px solid $border-color;
  border-radius: 8px;
  height: 42px;
  color: white;
  width: 100%;
  padding-left: 1rem;
  box-sizing: border-box;
}

.form-error {
  color: red;
  margin-top: 8px;
}

.folder-menu {
  background-color: $common-modal-header-bg-color;
  border-radius: 5px;
  min-height: 150px;
  max-height: 400px;
  overflow: auto;
  width: 100%;
  color: white;
  margin-bottom: 1rem;
  padding: 10px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.folder {
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
}
</style>
