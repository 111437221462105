<template>
  <div v-if="formStep === 1">
    <FirstStepForm
      @search-products="searchProducts"
      @add-bottle="setBottleToAdd"
    />
  </div>

  <div v-if="formStep === 2 && productToAdd">
    <SecondStepForm
      :product-to-add="productToAdd"
      @remove-product="removeProductToAdd"
      @close-modal="$emit('closeModal')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FirstStepForm from '@/components/modules/portfolio/createBottleForm/firstStepForm.vue'
import SecondStepForm from '@/components/modules/portfolio/createBottleForm/secondStepForm.vue'

export default {
  name: 'createBottleForm',
  components: { SecondStepForm, FirstStepForm },
  emits: ['closeModal'],
  data() {
    return {
      formStep: 1,
      productToAdd: null,
    }
  },
  computed: {
    ...mapGetters({
      getProducts: 'getProducts',
      getAuthStatus: 'getIsAuth',
      getSearchPending: 'getSearchPending',
    }),
  },
  methods: {
    ...mapActions({
      autoSearchAuth: 'autocompleteSearchAuth',
    }),
    returnUrl(pictures) {
      if (pictures) {
        return pictures[0]
      }
    },
    searchProducts(searchProducts, key) {
      if (
        searchProducts.value?.length > 0 ||
        searchProducts.search?.length > 0
      ) {
        if (!this.getSearchPending['portfolio']) {
          const formattedPayload = {
            type: key,
            search: searchProducts.value
              ? searchProducts.value
              : searchProducts.search,
            spirit_type: searchProducts.spirit_type,
          }

          if (!formattedPayload.filters) {
            formattedPayload.filters = {
              spirit_type: null,
              country: null,
              age: null,
              vintage: null,
              single_cask: false
            }
          }

          this.last_pending_search = searchProducts

          this.autoSearchAuth(formattedPayload)
        }
      } else {
        this.$store.commit('RESET_SEARCH', { key: key, products: [] })
      }
    },
    setBottleToAdd(product) {
      this.productToAdd = product
      this.formStep = 2
    },
    removeProductToAdd() {
      this.productToAdd = null
      this.formStep = 1
    },
  },
}
</script>
