<template>
  <div class="header">
    <CommonModal
      v-if="isUpdateModalOpen"
      size="small"
      modal-title="Modification d'une bouteille"
      @close="closeModal"
    >
      <updateBottleForm
        :bottleToUpdate="bottleToUpdate"
        :ticker="getProduct.ticker"
        @close="closeModal"
      >
      </updateBottleForm>
    </CommonModal>
    <img
      class="image"
      v-if="getProduct.files_attachments[0]"
      :src="getProduct.files_attachments[0]"
    />
    <img class="image" v-else src="@/assets/svg/v2/bottle.svg" alt="Bottle" />
    <div class="title-bottle">
      <h3>{{ getProduct.title }}</h3>
      <div>
        <span>{{ getProduct.ticker }}</span>
        <p>{{ getProduct.isbn }}</p>
      </div>
    </div>
  </div>

  <div class="buy-sell">
    <Transition name="action-headband-scale">
      <div v-if="checkedBottles.length > 0" class="action-headband">
        {{ checkedBottles.length }} {{ $t('portfolio.selected_bottles') }}

        <div>
          <button @click="handleMoveModal">
            <img
              :src="require('@/assets/svg/v2/portfolio/moveButton.svg')"
              alt="Move icon"
            />
            {{ $t('portfolio.move') }}
          </button>
          <button @click="deleteSelectedReferences">
            <img
              :src="require('@/assets/svg/v2/portfolio/trashButton.svg')"
              alt="Trash icon"
            />
            {{ $t('portfolio.delete') }}
          </button>
        </div>
      </div>
    </Transition>
    <div class="table buy">
      <div class="header-buy-sell">
        <div class="header-text">
          <img
            :src="require('@/assets/svg/v2/portfolio/cote.svg')"
            alt="Cote icon"
          />
          <h3>{{ $t('portfolio.boughts') }}</h3>
          <p>
            ({{
              Object.keys(
                this.pfmBottleDetailsBUY[this.selectedItem.ticker][0].items
              ).length
            }})
          </p>
        </div>
        <div class="header-price"></div>
      </div>

      <div class="table-container">
        <table
          v-if="
            Object.keys(
              this.pfmBottleDetailsBUY[this.selectedItem.ticker][0].items
            ).length >= 1
          "
        >
          <thead>
            <tr class="no-wrap">
              <th style="width: 40px"></th>
              <th style="width: 40px"></th>
              <th style="width: 100px">{{ $t('portfolio.th_date') }}</th>
              <th style="width: 80px" class="center">{{ $t('portfolio.th_quantity') }}</th>
              <th style="width: 90px">{{ $t('portfolio.th_net_price') }}</th>
              <th style="width: 90px">{{ $t('portfolio.th_gross_price') }}</th>
              <th style="width: 90px">{{ $t('portfolio.th_fees') }}</th>
              <th style="width: 120px">{{ $t('portfolio.th_storage_location') }}</th>
              <th class="center">{{ $t('portfolio.th_holding_length') }}</th>
              <th>{{ $t('portfolio.th_attachments') }}</th>
              <th>{{ $t('portfolio.th_buy_place') }}</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="item in this.pfmBottleDetailsBUY[
                this.selectedItem.ticker
              ][0].items"
              :key="item"
              class="no-wrap"
            >
              <td>
                <CommonCheckbox
                  v-model="checkedBottles[item.bottle_uuid]"
                  size="15"
                  @click.stop="handleBottleChecked($event, item)"
                />
              </td>
              <td>
                <button
                  @click.prevent="updateUniqueBottle(item)"
                  class="edit-button"
                >
                  <img
                    :src="require('@/assets/svg/v2/portfolio/edit.svg')"
                    alt="Pen icon"
                  />
                </button>
              </td>
              <td>{{ item.sold_date !== null ? item.sold_date : '-' }}</td>
              <td class="center">
                {{ item.quantity !== null ? item.quantity : '-' }}
              </td>
              <td>
                {{ item.price !== null ? item.price + ' €' : '-' }}
              </td>
              <td>
                {{
                  item.opt_price_brut !== null
                    ? item.opt_price_brut + ' €'
                    : '-'
                }}
              </td>
              <td>
                {{
                  item.opt_price_fees !== null
                    ? item.opt_price_fees + ' €'
                    : '-'
                }}
              </td>
              <td>{{ item.location !== null ? item.location : '-' }}</td>
              <td class="center">
                {{ item.hold_length !== null ? item.hold_length + '' : '-' }}
              </td>
              <td>
                {{
                  item.files_attachments !== null ? item.files_attachments : '-'
                }}
              </td>
              <td>{{ item.buy_place !== null ? item.buy_place : '-' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="table sell">
      <div class="header-buy-sell">
        <div class="header-text">
          <img
            :src="require('@/assets/svg/v2/portfolio/crossRed.svg')"
            alt="CrossRed icon"
          />
          <h3>{{ $t('portfolio.sales') }}</h3>
          <p>
            ({{
              Object.keys(
                this.pfmBottleDetailsSELL[this.selectedItem.ticker][0].items
              ).length
            }})
          </p>
        </div>
        <div class="header-price"></div>
      </div>

      <div>
        <div class="table-container">
          <table
            v-if="
              Object.keys(
                this.pfmBottleDetailsSELL[this.selectedItem.ticker][0].items
              ).length >= 1
            "
          >
            <thead>
              <tr class="no-wrap">
                <th style="width: 40px"></th>
                <th style="width: 40px"></th>
                <th style="width: 100px">{{ $t('portfolio.th_date') }}</th>
                <th style="width: 90px" class="center">{{ $t('portfolio.th_quantity') }}</th>
                <th style="width: 90px">{{ $t('portfolio.th_net_price') }}</th>
                <th style="width: 90px">{{ $t('portfolio.th_gross_price') }}</th>
                <th style="width: 90px">{{ $t('portfolio.th_fees') }}</th>
                <th>{{ $t('portfolio.th_attachments') }}</th>
                <th>{{ $t('portfolio.sell_place') }}</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="item in this.pfmBottleDetailsSELL[
                  this.selectedItem.ticker
                ][0].items"
                :key="item"
                class="no-wrap"
              >
                <td>
                  <CommonCheckbox
                    v-model="checkedBottles[item.bottle_uuid]"
                    size="15"
                    @click.stop="handleBottleChecked($event, item)"
                  />
                </td>
                <td>
                  <button
                    @click.prevent="updateUniqueBottle(item)"
                    class="edit-button"
                  >
                    <img
                      :src="require('@/assets/svg/v2/portfolio/edit.svg')"
                      alt="Pen icon"
                    />
                  </button>
                </td>
                <td style="width: 100px">{{ item.sold_date }}</td>
                <td class="center">{{ item.quantity }}</td>
                <td>{{ item.price !== null ? item.price + ' €' : '-' }}</td>
                <td>
                  {{
                    item.opt_price_brut !== null
                      ? item.opt_price_brut + ' €'
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    item.opt_price_fees !== null
                      ? item.opt_price_fees + ' €'
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    item.files_attachments !== null
                      ? item.files_attachments
                      : '-'
                  }}
                </td>
                <td>{{ item.sell_place !== null ? item.sell_place : '-' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonCheckbox from '@/components/commons/checkbox.vue'
import CommonModal from '@/components/commons/modal.vue'
import updateBottleForm from '@/components/modules/portfolio/updateBottleForm/updateBottleForm.vue'
import { mapActions } from 'vuex'

export default {
  name: 'bottleModalContent',
  components: { CommonCheckbox, updateBottleForm, CommonModal },
  emits: ['delete', 'refresh'],
  props: {
    pfmBottleDetailsSELL: {
      type: Object,
      required: true,
    },
    pfmBottleDetailsBUY: {
      type: Object,
      required: true,
    },
    getProduct: {
      type: Object,
      required: false,
    },
    selectedItem: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      bottleToUpdate: null,
      checkedBottles: [],
      moveModal: false,
      isUpdateModalOpen: false,
    }
  },
  methods: {
    ...mapActions({
      deleteBottles: 'deleteBottles',
    }),
    updateUniqueBottle(item) {
      if (!this.isUpdateModalOpen) {
        this.bottleToUpdate = item
        this.isUpdateModalOpen = true
      } else {
        this.bottleToUpdate = null
        this.isUpdateModalOpen = false
      }
    },
    closeModal() {
      this.isUpdateModalOpen = false
      this.$emit('refresh')
      this.bottleToUpdate = null
    },
    deleteSelectedReferences() {
      this.$emit('delete', this.checkedBottles)
      this.checkedBottles = []
    },
    handleMoveModal() {
      this.$emit('move', this.checkedBottles)
    },
    async handleBottleChecked(event, item) {
      const bottleIndex = this.checkedBottles.findIndex(
        (bottle) => bottle.bottle_uuid === item.bottle_uuid
      )

      if (bottleIndex === -1) {
        this.checkedBottles.push({
          bottle_uuid: item.bottle_uuid,
          folder_uuid: item.folder_uuid,
        })
      } else {
        this.checkedBottles.splice(bottleIndex, 1)
      }
      event.stopPropagation()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/root';

.action-headband {
  height: 50px;
  padding: 0 1.5rem;
  background-color: rgb(38, 102, 255);
  color: white;
  border-radius: 12px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
  }

  div button {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: transparent;
    color: white;
    border: none;
    margin-left: 20px;
  }
}
.edit-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
}
.action-headband-scale-enter-active,
.action-headband-scale-leave-active {
  transition: transform 0.3s ease;
}

.action-headband-scale-enter-to,
.action-headband-scale-leave-from {
  transform: scale(1);
}

.action-headband-scale-leave-to,
.action-headband-scale-enter-from {
  transform: scale(0);
}

.no-wrap {
  white-space: nowrap;
}

.table-container {
  overflow-x: auto;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $background;
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $grey40;
  }
  & {
    scrollbar-width: thin;
    scrollbar-color: white $background;

    &::-moz-scrollbar-track {
      background: $background;
    }

    &::-moz-scrollbar-thumb {
      background-color: white;
      border-radius: 10px;
    }

    &::-moz-scrollbar-thumb:hover {
      background-color: $grey40;
    }
  }
}

.table {
  border: 1px solid #ffffff1f;
  margin-top: 20px;
  border-radius: 12px;

  table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
    overflow: auto;

    thead {
      color: #ffffff66;
      font-size: 16px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1215686275);

      th {
        padding: 15px 10px;
        padding-top: 10px;
        color: white;
      }
    }
  }

  .center {
    text-align: center;
  }

  tbody tr td:first-child {
    color: #ffffff99;
    gap: 20px;
  }

  tbody td {
    padding: 15px 10px;
    color: #ffffff;
  }

  tbody tr:nth-child(even) {
    background-color: #444444;
  }

  tbody tr:nth-child(odd) {
    background-color: #333333;
  }
}

.buy {
  background: linear-gradient(
    180deg,
    rgba(132, 204, 22, 0) 0%,
    rgba(132, 204, 22, 0.08) 99.99%
  );
}

.sell {
  background: linear-gradient(
    180deg,
    rgba(244, 63, 94, 0) 0%,
    rgba(244, 63, 94, 0.08) 100%
  );
}

.header-buy-sell {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
}

.header-text {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    margin-left: 20px;
    color: white;
    font-weight: 600;
    font-size: 18px;
  }

  p {
    color: #ffffff66;
    margin-left: 5px;
    font-weight: 600;
    font-size: 18px;
  }
}

.header-price {
  p {
    color: #ffffff66;
  }

  span {
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  background-color: #444444;
  padding: 1rem 2rem;
  color: #ffffff;
  border-bottom: 1px solid #ffffff3d;

  .title-bottle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    margin-left: 20px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
    }

    div span {
      color: #ffffff66;
      border: 1px solid #ffffff29;
      border-radius: 6px;
      padding: 4px 6px 4px 6px;
    }

    div p {
      color: #ffffff99;
      margin-left: 10px;
    }
  }
}

.buy-sell {
  margin-top: 2rem;
  padding-top: 1px;
}

.header .image {
  width: 80px;
  height: 80px;
  border-radius: 12px;
}
</style>
