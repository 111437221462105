<template>
  <div class="tooltip-content">
    <div @click="moveFolder">
      {{ $t('portfolio.move_in_another_folder') }}
    </div>
    <el-divider class="divider" />
    <div @click="renameFolder">
      <span class="rename-text">{{ $t('portfolio.rename') }}</span>
    </div>
    <el-divider class="divider" />
    <div class="red" @click="deleteFolder">
      {{ $t('portfolio.delete') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'folderOptionsTooltip',
  emits: ['deleteFolder', 'renameFolder', 'moveFolder'],
  props: {
    folder: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deleteFolder() {
      this.$emit('deleteFolder')
    },
    renameFolder() {
      this.$emit('renameFolder', this.folder)
    },
    moveFolder() {
      this.$emit('moveFolder', this.folder)
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root';
$border-color: rgba(255, 255, 255, 0.3);

.tooltip-content {
  white-space: nowrap;
  padding: 0.4rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 3px;

  .red {
    color: $alert;
  }
}
.rename-text {
  color: white;
}
.divider {
  border-color: $border-color;
  margin: 0;
}
</style>
