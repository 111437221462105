<template>
  <div class="tooltip" :class="position" ref="tooltip">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'commonTooltip',
  props: {
    position: {
      type: String,
      default: 'top',
      validator: (value) =>
        ['left', 'left-folder', 'top', 'right', 'bottom'].includes(value),
    },
  },
  emits: ['close'],
  methods: {
    handleClickOutsideTooltip(event) {
      const tooltip = this.$refs.tooltip
      if (tooltip && !tooltip.contains(event.target)) {
        this.$emit('close')
      }
    },
  },
  mounted() {
    document.addEventListener('mousedown', this.handleClickOutsideTooltip)
  },
  beforeUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideTooltip)
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root';

$border-color: rgba(255, 255, 255, 0.3);

.tooltip {
  position: absolute;
  content: '';
  z-index: 999;
  background: $el-input-bg-color;
  border: 1px solid $border-color;
  border-radius: 12px;
}

.tooltip.left {
  left: 20px;
  top: -53px;
  transform: translateX(-100%);
}

.tooltip.left-folder {
  left: 20px;
  top: -53px;
  transform: translateX(-100%);
}

.tooltip.top {
  top: 0;
  transform: translate(-50%, -100%);
}

.tooltip.right {
  right: 0;
  transform: translateX(100%);
}

.tooltip.bottom {
  bottom: 0;
  transform: translate(-50%, 100%);
}
</style>
