<template>
  <form @submit.prevent="submitForm" class="folder-form">
    <label for="folder-name">{{ $t('portfolio.folder_name') }}</label>
    <div>
      <input
        v-model="folderToRenameName"
        type="text"
        name="folder-name"
        id="folder-name"
      />
      <p class="form-error">{{ formErrors.folderName }}</p>
    </div>

    <button type="submit">{{ $t('portfolio.rename_folder') }}</button>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'renameFolderModalContent',
  emits: ['close', 'renameFolder'],
  props: {
    folder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      folderToRenameName: '',
      formErrors: {
        folderName: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      folders: 'pfmFolders',
      pfmSettings: 'pfmSettings',
    }),
    folderTree() {
      const folderList = this.folders
      const formattedData = []

      const flattenFolderList = (folders, level = 0) => {
        if (!folders || !Array.isArray(folders)) return

        folders.forEach((folder) => {
          formattedData.push({
            label: folder.label,
            value: folder.value,
            level: level,
          })

          if (folder.children && folder.children.length > 0) {
            flattenFolderList(folder.children, level + 1)
          }
        })
      }

      flattenFolderList(folderList, 0)

      return formattedData
    },
  },
  watch: {
    folderToRenameName(newValue) {
      if (newValue.length > 30) {
        this.formErrors.folderName = this.$t('portfolio.max_characters')
      } else {
        this.formErrors.folderName = null
      }
    },
  },
  methods: {
    ...mapActions({
      updateFolder: 'updateFolder',
    }),
    async submitForm() {
      if (this.folderToRenameName.length < 1) {
        this.formErrors.folderName = this.$t('portfolio.empty_folder_name')
      }
      if (this.formErrors.folderName === null) {
        await this.updateFolder({
          name: this.folderToRenameName,
          folder_uuid: this.folder.value,
        })

        this.$emit('close')
      }
    },
    getFolderStyle(folder) {
      let style = { 'padding-left': `${folder.level * 20 + 20}px` }

      if (folder.value === this.selectedParentFolderId) {
        style['background-color'] = '#333333'
        style['border-bottom'] = '1px solid #2666ff'
      }

      return style
    },
    selectParentFolder(folderId) {
      this.selectedParentFolderId = folderId
    },
  },
  mounted() {
    this.selectedParentFolderId = this.folders[0].value
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root';
$border-color: rgba(255, 255, 255, 0.3);

.folder-form {
  padding: 1rem 0 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

button {
  border: none;
  border-radius: 50px;
  height: 40px;
  padding: 0 20px 0 20px;
  align-self: center;
  color: white;
  background-color: #1f55d8;

  &:hover {
    background-color: #143786;
  }
}

label {
  color: white;
}

input {
  background-color: $el-input-bg-color;
  border: 1px solid $border-color;
  border-radius: 8px;
  height: 42px;
  color: white;
  width: 100%;
  padding-left: 1rem;
  box-sizing: border-box;
}

.form-error {
  color: red;
  margin-top: 8px;
}

.folder-menu {
  background-color: $common-modal-header-bg-color;
  border-radius: 5px;
  min-height: 150px;
  max-height: 400px;
  overflow: auto;
  width: 100%;
  color: white;
  margin-bottom: 1rem;
  padding: 10px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.folder {
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
}
</style>
