<template>
  <div class="search-result-header">
    <h3>{{ $t('portfolio.research_bottle') }}</h3>
    <Search_V2
      :is-search-portfolio="true"
      page-key="portfolio"
      @search="searchProducts"
    />
  </div>
  <div class="search-result-body">
    <div v-if="getProducts['portfolio']?.length > 0" class="search-result">
      <div
        v-for="product in getProducts['portfolio']"
        :key="product.ticker_maitre"
        class="product"
      >
        <img
          v-if="returnUrl(product.files_attachments)"
          :src="returnUrl(product.files_attachments)"
          :alt="product.title"
          class="product-image"
        />
        <div v-else class="not-found-image-wrapper">
          <img
            :src="require('@/assets/svg/v2/bottle.svg')"
            alt="Product not found image"
            class="product-image-not-found"
          />
        </div>
        <div class="product-info">
          <p class="product-title">
            {{ product.title }}
          </p>
          <div class="product-data">
            <span>{{ product.ticker_maitre }}</span>
            <span>{{ product.isbn }}</span>
          </div>
        </div>
        <button class="product-button" @click="setBottleToAdd(product)">
          {{ $t('portfolio.add') }}
        </button>
      </div>
    </div>

    <div v-else class="empty-bottle">
      <div>
        <img src="@/assets/svg/v2/bottle.svg" alt="Bottle" />
        <p>{{ $t('portfolio.no_bottles_found_text') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Search_V2 from '@/components/modules/search_V2.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'firstStepForm',
  components: { Search_V2 },
  emits: ['searchProducts', 'addBottle'],
  computed: {
    ...mapGetters({
      getProducts: 'getProducts',
    }),
  },
  methods: {
    returnUrl(pictures) {
      if (pictures) {
        return pictures[0]
      }
      return null
    },
    searchProducts(searchProducts, key) {
      this.$emit('searchProducts', searchProducts, key)
    },
    setBottleToAdd(product) {
      this.$emit('addBottle', product)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root';

$border-color: rgba(255, 255, 255, 0.3);
$scrollbar-thumb-color: rgb(38, 102, 255);
$scrollbar-track-color: rgba(0, 0, 0, 0.2);

.empty-bottle {
  margin-top: 20px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      z-index: 2;
    }

    p {
      color: $subtitle;
      font-size: 16px;
      font-weight: 500;
      max-width: 315px;
      text-align: center;
      margin-top: 28px;
    }
  }
}

h3,
.product-title {
  color: white;
}

.search-result-body {
  padding: 2rem 0;
}

.search-result-header {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.search-result {
  height: 23rem;
  overflow: auto;
  border-radius: 12px;
}

.product {
  display: flex;
  align-items: center;
  border: 1px solid $border-color;
  border-radius: 12px;
  margin: 0 1rem 1rem 0;
  padding: 1rem;
  background-color: #444444;

  &-image,
  &-info .product-data span:first-child,
  .not-found-image-wrapper {
    border: 1px solid $border-color;
    border-radius: 8px;
  }

  &-image,
  .not-found-image-wrapper {
    height: 70px;
    min-width: 70px;
  }

  .not-found-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    padding-left: 1rem;

    & .product-title {
      font-size: 18px;
    }

    & .product-data {
      span:first-child {
        color: $border-color;
        padding: 4px 6px;
        font-size: 14px;
      }

      span:nth-child(2) {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.6);
        margin-left: 10px;
      }
    }
  }

  &-button {
    background-color: transparent;
    color: white;
    font-weight: bold;
    padding: 6px 8px;
    border: none;
    border-radius: 8px;
  }
}

.product-image-not-found {
  width: 20px;
}

.search-result::-webkit-scrollbar {
  width: 10px;
}

.search-result::-webkit-scrollbar-track {
  background: $background;
}

.search-result::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 10px;
}

.search-result::-webkit-scrollbar-thumb:hover {
  background-color: $grey40;
}
</style>
