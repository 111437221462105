<template>
    <form @submit.prevent="submitForm" class="folder-form">

        <h4>{{ $t('portfolio.move_modal_title_origin') }} {{ origin.label }}</h4>
        <p>{{ $t('portfolio.move_modal_content_infos') }}</p>

      <div class="folder-menu">
        <div
          v-for="folder in folderTree"
          :key="folder.value"
          :style="getFolderStyle(folder)"
          class="folder"
          @click="selectParentFolder(folder.value)"
        >
          {{ folder.label }}
        </div>
      </div>
  
      <button v-if="canSubmit" type="submit">{{ $t('portfolio.move') }}</button>
    </form>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex'
  
  export default {
    name: 'moveToAnotherFolder',
    emits: ['close'],
    props: {
        origin: {
            type: Object,
            required: true
        }
    },
    data() {
      return {
        selectedParentFolderId: ''
      }
    },
    computed: {
      ...mapGetters({
        folders: 'pfmFolders',
      }),
      folderTree() {
        const folderList = this.folders
        const formattedData = []
  
        const flattenFolderList = (folders, level = 0) => {
          if (!folders || !Array.isArray(folders)) return
  
          folders.forEach((folder) => {
            formattedData.push({
              label: folder.label,
              value: folder.value,
              level: level,
            })
  
            if (folder.children && folder.children.length > 0) {
              flattenFolderList(folder.children, level + 1)
            }
          })
        }
  
        flattenFolderList(folderList, 0)
  
        return formattedData
      },
      canSubmit() {
        if (this.origin.value !== this.selectedParentFolderId) {
            return true;
        }
        return false;
      }
    },
    methods: {
      ...mapActions({
        createFolder: 'createFolder',
        moveFolderInAnotherFolder: 'moveFolderInAnotherFolder'
      }),
      async submitForm() {
        const payload = {
            origin: this.origin.value,
            target: this.selectedParentFolderId
        }

        await this.moveFolderInAnotherFolder(payload).then(() => {
            this.$emit('close');
        })
      },
      getFolderStyle(folder) {
        let style = { 'padding-left': `${folder.level * 20 + 20}px` }
  
        if (folder.value === this.selectedParentFolderId) {
          style['background-color'] = '#333333'
          style['border-bottom'] = '1px solid #2666ff'
        }
  
        return style
      },
      selectParentFolder(folderId) {
        this.selectedParentFolderId = folderId
      },
    },
    mounted() {
      this.selectedParentFolderId = this.folders[0].value
    },
  }
  </script>
  
  <style scoped lang="scss">
  @import '@/assets/styles/root';
  $border-color: rgba(255, 255, 255, 0.3);
  
  .folder-form {
    padding: 1rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  button {
    border: none;
    border-radius: 50px;
    height: 40px;
    padding: 0 20px 0 20px;
    align-self: center;
    color: white;
    background-color: #1f55d8;
  
    &:hover {
      background-color: #143786;
    }
  }
  
  label {
    color: white;
  }
  
  .folder-menu {
    background-color: $common-modal-header-bg-color;
    border-radius: 5px;
    min-height: 150px;
    max-height: 400px;
    overflow: auto;
    width: 100%;
    color: white;
    margin-bottom: 1rem;
    padding: 10px;
    box-sizing: border-box;
  
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  
  .folder {
    cursor: pointer;
    padding: 10px;
    border-radius: 12px;
  }
  </style>
  